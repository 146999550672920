import React, { useEffect, useState } from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import Box from "reusecore/src/elements/Box";
import PlanCard from "common/src/components/PlanCard";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Container from "common/src/components/UI/Container";
import Button from "reusecore/src/elements/Button";
import GlideCarousel from "common/src/components/GlideCarousel";
import GlideSlide from "common/src/components/GlideCarousel/glideSlide";
import TestimonialSecWrapper, {
  ImageWrapper,
  PostArea,
  TestimonialItem,
} from "./bannerSlider.style";

const TestimonialSection = ({
  reviewStyle,
  TestimonialMeta,
  nameStyle,
  designationStyle,
}) => {
  //Carousel Options
  const carouselOptions = {
    type: "carousel",
    autoplay: 3000,
    perView: 1,
    animationDuration: 600,
  };

  const plansByStatusByUpdatedAt = `
    query PlansByStatusByUpdatedAt(
      $status: PlanStatusType
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelPlanFilterInput
      $limit: Int
      $nextToken: String
    ) {
      plansByStatusByUpdatedAt(
        status: $status
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          title
          tags
          description
          updatedAt
          images {
            index
            bucket
            region
            key
          }
          price
        }
        nextToken
      }
    }
  `;

  const [plans, setPlans] = useState([]);
  const gunsyappBaseUrl =
    process.env.GATSBY_ACTIVE_ENV === "prod"
      ? "https://app.gunsy.jp/search/plan"
      : "https://testapp.gunsy.jp/search/plan";
  const fetchImageUrl = async (s3object) => {
    try {
      Storage.configure({
        AWSS3: {
          bucket: s3object.bucket,
          region: s3object.region,
        },
      });
      const checkImages = await Storage.list(s3object.key);
      if (checkImages.length > 0) {
        const imageUrl = await Storage.get(s3object.key);
        return imageUrl;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const setImageUrlToPlan = async (plan) => {
    if (!plan.images || (plan.images && plan.images.length === 0)) {
      plan.imageUrl = null;
      return "success";
    }
    try {
      plan.imageUrl = await fetchImageUrl(plan.images[0]);
    } catch (error) {
      console.log(error);
      plan.imageUrl = null;
    } finally {
      return "success";
    }
  };

  const fetchPlans = async () => {
    try {
      const res = await API.graphql({
        query: plansByStatusByUpdatedAt,
        variables: {
          status: "PUBLIC",
          limit: 9,
          sortDirection: "DESC",
        },
        authMode: "AWS_IAM",
      });
      const items = res.data.plansByStatusByUpdatedAt.items;
      await Promise.all(items.map((plan) => setImageUrlToPlan(plan)));
      setPlans(items);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <TestimonialSecWrapper id="testimonial_section">
      <Container className="sliderContainer">
        <div className="sliderStyle">
          <PostArea className="center">
            {plans.map((plan, index) => (
              // {plan.imageUrl !== null ?(
              <a
                className="products"
                href={`${gunsyappBaseUrl}/${plan.id}`}
                target="__blank"
              >
                <PlanCard
                  className="product"
                  key={`plan-slide-${index}`}
                  thumbUrl={plan.imageUrl}
                  title={
                    plan.title.length <= 32
                      ? plan.title.slice(0, 32)
                      : `${plan.title.slice(0, 31)} …`
                  }
                  excerpt={
                    plan.description.length <= 72
                      ? plan.description.slice(0, 72)
                      : `${plan.description.slice(0, 71)} …`
                  }
                  // link={
                  //   <a
                  //     className="learn__more-btn"
                  //     href={`${gunsyappBaseUrl}/${plan.id}`}
                  //     target="__blank"
                  //   >
                  //     価格：{plan.price}円
                  //   </a>
                  // }
                  price={`価格：${plan.price}円`}
                />
              </a>
            ))}
          </PostArea>
        </div>
      </Container>
    </TestimonialSecWrapper>
  );
};

TestimonialSection.propTypes = {
  reviewStyle: PropTypes.object,
  TestimonialMeta: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
};

TestimonialSection.defaultProps = {
  reviewStyle: {
    fontSize: ["16px", "16px", "16px", "16px", "16px"],
    fontWeight: "400",
    color: "rgb(82, 95, 127)",
    lineHeight: "1.74",
    mb: ["30px", "30px", "30px", "30px", "40px"],
  },
  TestimonialMeta: {
    flexBox: true,
    alignItems: "center",
  },
  nameStyle: {
    as: "h3",
    fontSize: ["16px", "17px", "18px", "18px", "20px"],
    fontWeight: "500",
    color: "rgb(50, 50, 93)",
    mb: "5px",
    fontFamily: "Poppins",
  },
  designationStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "rgb(82, 95, 127)",
    mb: "0",
    ml: "1px",
    fontFamily: "Poppins",
  },
};

export default TestimonialSection;
