import styled from "styled-components";
import BannerBG from "common/src/assets/image/crypto/white_bg1.png";

const BannerWrapper = styled.section`
  padding-top: 180px;
  padding-bottom: 400px;
  background-image: url(${BannerBG});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1440px) {
    padding-bottom: 305px;
  }
  @media (max-width: 990px) {
    padding-top: 150px;
    padding-bottom: 210px;
  }
  @media (max-width: 768px) {
    opacity: 100%;
    background-image: none;
    background-color: #0168b3;
  }

  @media only screen and (max-width: 480px) {
    padding-top: 130px;
    padding-bottom: 110px;
    background-color: #0168b3;
  }

  .particle {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 768px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }
  .row {
    position: relative;
    z-index: 1;
  }
  .button__wrapper {
    margin-top: 40px;
    .reusecore__button {
      border-radius: 6px;
      &:first-child {
        transition: all 0.3s ease;
        margin-right: 0px;
        @media (max-width: 1220px) {
          margin-right: 0;
        }
        &:hover {
          box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
        }
      }
      &:nth-child(2) {
        padding-left: 0;
        @media (max-width: 1100px) {
          padding-left: 15px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
          padding-top: 15px;
        }
        margin-top: 15px;
        .btn-text {
          font-weight: 700;
          margin-top: 7px;
        }
        .btn-icon {
          margin-top: 3px;
          margin-left: 3px;
        }
      }
      > a {
        font-weight: 700;
        color: #fff;
      }
    }
    .btnWithoutColor {
      padding: 0px 0px !important;
      justify-content: center;
      .btn-icon {
        svg {
          width: 30px;
          height: 30px;
        }
      }
      .btn-text {
        padding-left: 15px;
        font-size: 15px;
        font-weight: 500 !important;
        font-family: "Poppins", sans-serif;
      }
      &:hover {
        box-shadow: none !important;
      }
      @media (max-width: 570px) {
        margin-right: 20px;
        margin-left: 15px;
      }
    }
  }
`;
export const BgImageWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: auto;
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: -1px 0;   
    
    }    
  }
`;
export default BannerWrapper;
