import React from "react";
import PropTypes from "prop-types";
import NoImage from "common/src/assets/image/NoImage.png";

const PlanCard = ({ className, thumbUrl, title, excerpt, price }) => {
  // Add all classs to an array
  const addAllClasses = ["plan_card"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <div className={addAllClasses.join(" ")}>
      <div className="thumbnail">
        <img src={thumbUrl ? thumbUrl : NoImage} alt={title} />
      </div>
      <div className="content">
        <h3 className="title">{title}</h3>
        <div className="excerpt">{excerpt}</div>
        <p className="price">{price}</p>
      </div>
    </div>
  );
};

PlanCard.propTypes = {
  className: PropTypes.string,
  thumbUrl: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  price: PropTypes.string,
};

export default PlanCard;
