import styled from "styled-components";

const TestimonialSecWrapper = styled.section`
  padding: 0;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  z-index: 1;
  margin-top: -130px;
  @media (min-width: 1600px) {
    margin-top: -180px;
  }
  @media (max-width: 1200px) {
    margin-top: -80px;
  }
  @media (max-width: 990px) {
    margin-top: -40px;
  }
  @media (max-width: 575px) {
    padding: 60px 0;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
    padding: 60px 0 0;
  }
  .glide--carousel {
    display: flex;
    flex-direction: column;
    .glide__track {
      order: 2;
    }
    .glide__bullets {
      .glide__bullet {
        border-radius: 50%;
        background-color: rgb(20, 227, 186);
        opacity: 0.231;
        transition: all 0.2s ease;
        &.glide__bullet--active {
          background-color: rgb(20, 227, 186);
          width: 24px;
          height: 8px;
          border-radius: 15px;
          opacity: 1;
        }
      }
    }
    .glide__slide {
      p {
        @media (max-width: 480px) {
          margin-right: 30px;
          margin-left: 5px;
          max-width: 320px;
        }
      }
    }
  }
  .testimonial_item {
    max-width: 100%;
    @media (max-width: 480px) {
      max-width: 340px;
    }
  }
  .TagParentStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .TagSonStyle {
    flex-basis: fill;
    padding: 2px 12px;
    margin: 2px 3px 2px 0;
    font-size: 13px;
    background-color: #e0e0e0;
    border: 0px;
    border-radius: 20px;
    padding: 2px 12px;
    color: #525f7f;
  }
  .titleStyle {
    margin: 15px 0px 0px 0px;
    max-width: 80%;
  }
  .sliderStyle {
    display: flex;
    white-space: normal;
    word-break: break-all;
    max-width: 100%;
    position: relative;
    justify-content: center;
  }
  .sliderContainer {
    @media (min-width: 1220px) {
      max-width: 90%;
      width: 100%;
    }
  }
  .products {
    display: contents;
    justify-content: center;
  }
  .center {
    justify-content: center;
  }
`;

export const TestimonialItem = styled.div`
  padding: 30px 0;
  background-color: #fff;
  transition: 0.425s ease;
  @media (max-width: 1300px) {
    padding: 20px 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 120px;
  height: 80px;
  flex-basis: 50px;
  display: block;
  overflow: hidden;
  margin-right: 15px;
  margin-top: 30px;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export const PostArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -50px;
  @media only screen and (max-width: 1440px) {
    margin: 0 -35px;
  }
  @media only screen and (max-width: 1360px) {
    margin: 0 -30px;
  }
  @media only screen and (max-width: 991px) {
    margin: 0 -20px;
  }

  .plan_card {
    width: 360px;
    height: 400px;
    margin: 10px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #cccccc;
    box-shadow: 0 0 100px rgba(175, 182, 194, 0.2);
    background-color: white;
    position: relative;

    .thumbnail {
      display: flex;
      justify-content: center;
      img {
        width: 100%;
        height: 160px;        
        max-height: 160px;
        object-fit: cover;
      }
      border-bottom: 1px solid;
      border-color: #cccccc;
    }
    .content {
      height: 60px;
      margin: 
      padding: 42px 50px 50px;
      @media only screen and (max-width: 1360px) {
        padding: 1px 5px 0px 5px;
      }
      @media only screen and (max-width: 991px) {
        // padding: 20px 30px 35px;
        padding: 1px 5px 0px 5px;
      }

      .title {
        height: 60px;
        font-size: 20px;
        line-height: 27px;
        font-weight: 800;
        color: #c53d43;
        margin: 10px 10px 1px;
        border-bottom: 1px dotted;
        border-color: #cccccc;
      }

      .excerpt {
        height: 120px
        font-size: 18px;
        line-height: 1.5em;
        font-weight: 400;
        margin: 10px 10px 1px;
        color: #525f7f;
      }

      .price {
        display: flex;
        height: 20px
        font-size: 18px;
        line-height: 1.5em;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 1px;
        color: #525f7f;
        position: absolute;
        right: 15px;
        bottom: 15px;
      }
    }

    &:nth-child(2) {
      .content {
        .title {
          padding-right: 78px;
          @media only screen and (max-width: 991px) {
            padding-right: 0;
          }
        }
      }
    }

    .login-button {
      button {
        border-radius: 6px;
        margin-bottom: 30px;
        margin-left: 25px;
        background-color: #0168b3;
        @media (max-width: 768px) {
          background-color: #88b83e;
        }
        &:hover {
          box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
          background-color: #00467a;
          @media (max-width: 768px) {
            background-color: #78a336;
          }
        }
        > span {
          font-weight: 700;
          letter-spacing: 1px;
        }
      }
    }
    .learn_more {
      .learn__more-btn {
        float: right;
        font-size: 12px;
      }
    }
  }
`;

export default TestimonialSecWrapper;
