import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

function GA({ googleAnalyticsIds }) {
  if (googleAnalyticsIds.length === 0) {
    return null;
  } else {
    return (
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${
            googleAnalyticsIds[0]
          }`}
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          ${googleAnalyticsIds
            .map((id) => `gtag('config', "${id}");`)
            .join("\n")}
        `}
        </script>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=c7630d8c-b820-462e-b968-729634d1097b"
        ></script>
      </Helmet>
    );
  }
}

GA.defaultProps = {
  googleAnalyticsIds: [],
};

GA.propTypes = {
  googleAnalyticsIds: PropTypes.array,
};

export default GA;
