const categories = [
  'マーケティング',
  'IT・インターネット',
  'ビジネス・経営',
  '人事・人材育成',
  '財務・会計',
  '法務・総務',
  '医療・福祉',
  '学習・受験',
  '生活',
  '資格・キャリアアップ',
  '健康',
  '趣味',
  'イベント',
]

export default categories
