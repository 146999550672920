const tags = {
  マーケティング: [
    "マーケティング",
    "ネットマーケティング",
    "市場調査",
    "データ分析",
    "コミュニケーション",
    "販促・SP",
    "CRM",
    "広告宣伝",
    "ダイレクトマーケティング",
    "プロダクトマネジメント",
    "ブランディング",
    "プライシング",
    "テストマーケティング",
    "展示会・セミナー・イベント",
    "その他",
  ],
  "IT・インターネット": [
    "Webサイト",
    "スマホサイト",
    "デジタルデバイス",
    "クラウド導入・移行",
    "システム導入・移行",
    "EC導入・移行",
    "ネットワーク導入・移行",
    "ネット広告",
    "SEO・SEM",
    "SNS",
    "ネットマーケティング",
    "ECマーケティング",
    "コンテンツマーケティング",
    "YouTube・動画",
    "運用・管理・保守",
    "プログラミング",
    "デザイン",
    "情報システム部門",
    "情報セキュリティ",
    "BCP・危機管理",
    "IT人材",
    "海外進出・オフショア",
    "その他",
  ],
  "ビジネス・経営": [
    "経営戦略",
    "起業・ベンチャー",
    "新規事業",
    "第２創業",
    "多角化・異業種進出",
    "営業・販促戦略",
    "イノベーション",
    "経営分析・診断",
    "危機管理",
    "サプライチェーン",
    "生産計画",
    "研究開発",
    "産官学連携",
    "海外進出",
    "CSR・社会貢献",
    "地方・地域活性化",
    "事業継承・後継者",
    "M&A",
    "企業提携・連携",
    "フランチャイズ・代理店",
    "助成金",
    "その他",
  ],
  "人事・人材育成": [
    "求人・採用",
    "働き方改革",
    "職場環境",
    "人事・人事制度",
    "人事評価・分析",
    "人材育成・教育",
    "キャリアアップ",
    "コーチング",
    "メンタルヘルス",
    "人事法務",
    "その他",
  ],
  "財務・会計": [
    "財務戦略",
    "会計戦略",
    "財務診断・分析",
    "株式・株式上場",
    "キャッシュフロー",
    "資金調達",
    "資産運用・資産管理",
    "投資・投資家",
    "経費節減",
    "その他",
  ],
  "法務・総務": ["法務戦略", "契約", "ポリシー", "総務・事務"],
  "医療・福祉": [
    "病院経営",
    "新規開院",
    "病院クリニック集客",
    "医療現場のIT化",
    "医療のAI化",
    "医療のロボット化",
    "医療コンプライアンス",
    "美容業界",
    "歯科業界",
    "医療業界求人",
    "製薬",
    "再生医療",
    "細胞治療",
    "医療事務",
    "薬価",
    "医療人材教育",
    "医療セミナー",
    "介護事業経営",
    "新規介護事業進出",
    "介護業界",
    "介護業界求人",
    "介護業界資格",
    "介護事務",
    "医療人材教育",
    "介護保険",
  ],
  "学習・受験": [
    "英会話",
    "受験英語",
    "日本語",
    "受験国語",
    "数学",
    "やり直しの数学",
    "受験数学",
    "日本史",
    "世界史",
    "家庭教師",
  ],
  生活: [
    "料理",
    "スポーツ",
    "ファッション",
    "メイクアップ",
    "一人暮らしの老人の話相手",
    "DIY",
    "目覚まし係",
    "朗読",
    "ダンス",
  ],
  "資格・キャリアアップ": [
    "デザイナー",
    "ゲームクリエイター",
    "カラーコーディネーター",
    "行政書士",
    "司法書士",
    "中小企業診断士",
    "情報処理安全確保支援士",
    "情報セキュリティ",
    "情報セキュリティマネジメント",
    "保育士",
    "社会保険労務士",
    "野菜ソムリエ",
    "ケアマネジャー",
    "リハビリ",
    "社会福祉士",
    "メンタルカウンセラー",
    "フィナンシャルプランナー",
    "プログラミング",
    "面接練習",
  ],
  健康: [
    "フィットネス",
    "健康アドバイザー",
    "筋トレ",
    "ダイエット",
    "自宅トレ",
    "若返り",
  ],
  趣味: [
    "きれいな字",
    "占い",
    "絵",
    "イラスト",
    "漫画",
    "楽器の上達",
    "ファッション",
    "手品",
    "海釣り",
    "川釣り",
    "茶道",
    "華道",
    "俳句",
    "短歌",
  ],
  イベント: [
    "宴会",
    "スポーツ大会",
    "社内行事",
    "イベントの請負",
    "地域イベント",
    "村おこし街おこし",
    "祭り",
    "その他イベント",
  ],
};

export default tags;
