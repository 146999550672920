import styled from "styled-components";

const BetaSectionWrapper = styled.section`
  padding: 30px 0 30px;
  .genre-box {
    margin-top: 100px;
    font-family: "Poppins", sans-serif;

    position: relative;
    @media (min-width: 1300px) {
      left: auto;
      max-width: 100%;
    }
  }
  .genre-intro {
    color: #c53d43;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 50px;
    text-align: center;
  }
  .genres {
    margin-right: auto;
    margin-left: auto;
    display: inline-flex;
    flex-wrap: wrap;
  }
  .genre {
    width: calc(100% / 3);
    margin-bottom: 80px;
    justify-content: center;
    @media (max-width: 1024px) {
      padding-left: 20px;
    }
    @media (max-width: 750px) {
      width: 100%;
      margin-left: 20px;
    }
    @media (min-width: 1300px) {
      padding-left: 100px;
    }
  }
  .category {
    font-size: 20px;
    color: #c53d43;
    padding: 10px;
    font-weight: 800;
  }
  .tags {
  }
  .tag {
    font-size: 16px;
    color: #525f7f;
    font-weight: 400;
    padding-left: 30px;
  }
`;
const FeatureSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: space-between;
    align-items: flex-start;
  }
`;
export { BetaSectionWrapper, FeatureSection };
