import styled from "styled-components";
import FooterIamge from "common/src/assets/image/crypto/footer.png";

const FooterWrapper = styled.section`
  padding: 120px 0;
  background-image: url(${FooterIamge});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  z-index: 1;
  @media (max-width: 1440px) {
    padding: 150px 0 60px 150px;
  }
 
  @media (max-width: 1186px) {
    padding: 0 0 60px;
  }
  .Language_search_select {
    max-width: 135px;
    @media (max-width: 575px) {
      height: 52px;
      margin-bottom: 20px;
    }
    @media (max-width: 480px) {
      height: 20px;
      margin-bottom: 0px;
    }
    .select__control,
    .select-field__wrapper {
      height: 100%;
    }
    .select__control {
      padding: 0 15px 0 0px;
      box-shadow: none;
      position: relative;
      border-color: transparent;
      background: transparent;
      @media (min-width: 576px) {
        border-color: transparent;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        &:before {
          content: '';
          position: absolute;
          width: 1px;
          height: 55%;
          background: transparent;
          display: block;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      .select__placeholder {
        font-size: 16px;
        color: #fff;
        font-family: 'Lato';
        font-weight: 400;
      }
      .select__indicator {
        color: #fff;
      }
      .select__value-container {
        padding: 0;
        .select__single-value {
          font-size: 16px;
          color: #fff;
          font-family: 'Lato';
          font-weight: 400;
        }
      }
    }
    .select__indicator-separator {
      display: none;
    }
  }
  .appDownload {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .imageWrapper {
    display: flex;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
    img {
      margin-right: 15px;
      @media (max-width: 1200px) {
        margin-bottom: 15px;
        margin-right: 0;
        width: 150px;
      }
    }
  }
  .btnWithoutColor_1 {
    margin-top: 0px !important;
    margin-bottom: 0px
    padding: 0px 0px !important;
    border: 0px 0px
    .btn-icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .btn-text {
      padding-left: 15px;
      font-size: 15px;
      font-weight: 500 !important;
      font-family: 'Poppins', sans-serif;
    }
    &:hover {
      box-shadow: none !important;
    }
  }
  .copyRight {
    margin-top: 120px;
    margin-left: 0;
    margin-right: 0;
    width: calc(100% - 80px);
    @media (max-width: 1440px) {
      margin-top: 80px;
    }
    @media (max-width: 768px) {
      width: calc(100% - 20px);
      margin-top: 60px;
    }
    @media (max-width: 600px) {
      margin-top: 20px;
    }
    .copyRightText {
      font-size: 16px;
      font-family: 'Lato';
      font-weight: 400;
      color: #fff;
      @media (max-width: 480px) {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    .footer_social {
      margin-left: auto;
      margin-top: -15px;
      width: 25%;
      float: left;
      @media (max-width: 600px) {
        margin-left: 0;
        margin-top: 15px;
      }
      a {
        &:hover {
          color: #fff;
          opacity: 0.85;
        }
      }
    }
  }
  .mainRow {
    margin-top: 50px;
  }
  .Button1 { 
    
    justfy-content: left;
    .btn-icon {
      svg {
        width: 20px;
        height: 20px;       
      }
    }   
  }  

  .Button2 {
    margin-left: -6px;   
    .btn-icon {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  .Button3 {
    width: 25%;   
    text-align:center;  
    
  }
  .Button4 {
    width: 25%;
    
    text-align:center;
  }  
  .hatena_position{
    padding-top:10px;
  }
  .hatenablog{
    color: white;
  }
  .item-position{
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    margin: 13% 0 0 0;
    @media (min-width: 600px) and (max-width: 990px){
      margin-top: 5%;
    }
    @media (min-width: 990px) and (max-width: 1280px){
      margin-top: 5%;
    }
  }
  .item-position > *{
    flex-basis: 25%;
    width: 100%;
    @media (max-width: 600px){
      flex-basis: 100%;
    }
    @media (min-width: 600px) and (max-width: 990px){
      flex-basis: 50%;
    }
  }
  .footer_list{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .footer_list > *{
    flex-basis: 33%;
    width: 100%;
    margin-bottom: 20px;
    @media (max-width: 600px){
      flex-basis: 100%;
    }
    @media (min-width: 600px) and (max-width: 990px){
      flex-basis: 50%;
    }

  }
}
.copyRightText_B {
      font-size: 16px;
      font-family: 'Lato';
      font-weight: 400;
      color: #fff;
      @media (max-width: 480px) {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    .copyRightText_B :visited{
      font-size: 16px;
      font-family: 'Lato';
      font-weight: 400;
      color: #fff;
      @media (max-width: 480px) {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
`;

const List = styled.ul``;

const ListItem = styled.li`
  a {
    color: #fff;
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      opacity: 0.85;
    }
  }
`;
export const BgImageWrapper = styled.div`
  position: absolute;
  left: 1px;
  width: 100%;
  top: -1px;
  @media (max-width: 1200px) {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export { List, ListItem };

export default FooterWrapper;
