import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import Image from "reusecore/src/elements/Image";
import FeatureBlock from "common/src/components/FeatureBlock";
import Container from "common/src/components/UI/Container";
import Particles from "../../Agency/Particle";
import { Icon } from "react-icons-kit";
import { socialTwitter } from "react-icons-kit/ionicons/socialTwitter";
import { facebook2 } from "react-icons-kit/icomoon/facebook2";
import BannerBG from "common/src/assets/image/crypto/white_bg3.png";
import BannerWrapper, { BgImageWrapper } from "./bannerSection.style";
import { GUNSYAPP_BASE_URL, ZENDESK_BASE_URL } from "../../../constant/URL";
const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  loginBtnStyle,
  description,
  outlineBtnStyle,
  button,
  menuWrapper,
}) => {
  const ButtonGroup = () => (
    <div class="button_0">
      <a
        className="login-button"
        href={`${GUNSYAPP_BASE_URL}/login`}
        target="_blank"
      >
        <Button title="ログイン" {...button} />
      </a>

      <div className="start-button_0">
        <a
          className="start-button"
          href={`${GUNSYAPP_BASE_URL}/register`}
          target="_blank"
        >
          <Button className="start-button" title="今すぐ始める" {...button} />
        </a>
      </div>

      <a
        className="search-button"
        href={`${GUNSYAPP_BASE_URL}/search`}
        target="_blank"
      >
        <Button title="軍師・商品検索" {...button} />
      </a>
    </div>
  );
  const ShareButtonGroup = () => (
    <div class="icon_0">
      <a href="https://twitter.com/gunsyJP" target="_blank">
        <Button
          title="Twitter"
          variant="textButton"
          iconPosition="left"
          icon={<Icon icon={socialTwitter} />}
          {...outlineBtnStyle}
          className="btnWithoutColor"
        />
      </a>

      <a href="https://www.facebook.com/WWWgunsyJP/" target="_blank">
        <Button
          title="Facebook"
          variant="textButton"
          iconPosition="left"
          icon={<Icon icon={facebook2} />}
          {...outlineBtnStyle}
          className="btnWithoutColor"
        />
      </a>
    </div>
  );
  return (
    <BannerWrapper id="banner_section">
      <Particles />
      <BgImageWrapper>
        <Image src={BannerBG} alt="banner background" />
      </BgImageWrapper>

      <div className="qqq">
        <FeatureBlock
          className="main_title"
          title={
            <Heading
              content="ネットであなたのスキル・能力を売り出す時代へ。"
              {...title}
            />
          }
        />
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              className="lead_sentence"
              description={
                <Text
                  content=<ul>
                    <li>
                      時代はアフターコロナ。個人の働き方も大幅に変革します。
                    </li>
                    <Text></Text>
                    <li>
                      在宅・リモートワークで仕事が加速する現在、個人のスキル・能力はネットで売る時代へ突入。「３密」を避けてオンラインで仕事・副業を受注しましょう。
                    </li>
                    <Text></Text>
                    <li>
                      gunsy（グンシー）なら、今日からすぐに、ネットで「コンサル」「カウンセリング」「コーチング」「アドバイザー」「指導」「先生」を販売できます。
                    </li>
                    <Text></Text>
                    <li>
                      経営者や個人の方はこれらを手軽に購入することができます。経営改革に、AI化に、助成金アドバイスに、ダイエット指導に、禁煙指導に、必要な時だけリーズナブルに利用できる、まさに頼れる「軍師」をお届けします。
                    </li>
                  </ul>
                  {...description}
                />
              }
            />
            <div class="button">
              <FeatureBlock button={<ButtonGroup />} />
              <FeatureBlock button={<ShareButtonGroup />} />
            </div>
          </Box>
        </Box>
      </div>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    mt: "-20px",
    alignItems: "center",
  },
  col: {
    pr: "15px",
    pl: "15px",
    width: ["100%", "100%", "100%", "60%", "65%"],
  },
  title: {
    fontSize: ["24px", "32px", "36px", "36px", "48px"],
    fontWeight: "700",
    color: "#ffff00",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px"],
    lineHeight: "1.31",
  },
  description: {
    fontSize: ["16px", "16px", "17px", "17px", "22px"],
    color: "#fff",
    mb: "0",
    maxWidth: "100%",
  },
  btnStyle: {
    minWidth: ["120px", "156px"],
    fontSize: "15px",
    fontWeight: "700",
    borderRadius: "6px",
    colors: "secondaryWithBg",
  },
  loginBtnStyle: {
    minWidth: ["120px", "156px"],
    fontSize: "15px",
    fontWeight: "700",
    borderRadius: "6px",
    colors: "loginBtnWithBg",
  },
  outlineBtnStyle: {
    minWidth: ["130px", "156px"],
    fontSize: "16px",
    fontWeight: "700",
    color: "#fff",
    mr: "10px",
    p: "5px 10px",
  },
  button: {
    type: "button",
    fontSize: "13px",
    fontWeight: "700",
    borderRadius: "4px",
    pl: "10px",
    pr: "10px",
    ml: "10px",
    colors: "secondaryWithBg",
    minHeight: "auto",
    height: "40px",
  },
};

export default BannerSection;
