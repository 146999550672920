import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Image from "reusecore/src/elements/Image";
import Heading from "reusecore/src/elements/Heading";
import Select from "reusecore/src/elements/Select";
import Container from "common/src/components/UI/Container";
import ContactSections from "../Contact";
import FooterWrapper, { List, ListItem, BgImageWrapper } from "./footer.style";
import AppImage from "common/src/assets/image/ride/footerapp.svg";
import PlaystoreImage from "common/src/assets/image/ride/footerplay.svg";
import FooterBG from "common/src/assets/image/crypto/footer-bg.svg";
import { socialTwitter } from "react-icons-kit/ionicons/socialTwitter";
import { facebook2 } from "react-icons-kit/icomoon/facebook2";
import { Icon } from "react-icons-kit";
import Button from "reusecore/src/elements/Button";
import { youtube } from "react-icons-kit/icomoon/youtube";
import { hatenaicon } from "common/src/components/HatenaIcon/hatenaicon";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { ZENDESK_BASE_URL } from "../../../constant/URL";

const Footer = ({ row, col, colOne, colTwo, titleStyle }) => {
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        menuWidget {
          id
          title
          menuItems {
            id
            text
            url
            offset
            staticLink
            isAnotherTab
          }
        }
        Language_NAMES {
          label
          value
        }
      }
    }
  `);

  return (
    <FooterWrapper id="footerSection">
      <BgImageWrapper>
        <Image src={FooterBG} alt="Footer background" />
      </BgImageWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Box className="row mainRow" {...row}>
          <div className="item-position">
            <div class="Button1">
              <a href="https://twitter.com/gunsyJP" target="_blank">
                <Button
                  title="Twitter"
                  variant="textButton"
                  iconPosition="left"
                  icon={<Icon icon={socialTwitter} />}
                  {...titleStyle}
                />
              </a>
            </div>
            <div class="Button1">
              <a href="https://www.facebook.com/WWWgunsyJP/" target="_blank">
                <Button
                  title="Facebook"
                  variant="textButton"
                  iconPosition="left"
                  icon={<Icon icon={facebook2} />}
                  {...titleStyle}
                />
              </a>
            </div>
            <div class="Button1">
              <a
                href="https://www.youtube.com/channel/UCKmA_x5nXcx6tdIAiVUsKjg"
                target="_blank"
              >
                <Button
                  title="Youtube"
                  variant="textButton"
                  iconPosition="left"
                  icon={<Icon icon={youtube} />}
                  {...titleStyle}
                />
              </a>
            </div>
            <div class="Button2">
              <a href="https://gunsy.hatenablog.com/" target="_blank">
                <Button
                  title="hatenablog"
                  variant="textButton"
                  iconPosition="left"
                  icon={<Icon icon={hatenaicon} />}
                  {...titleStyle}
                />
              </a>
            </div>
          </div>
        </Box>

        {/* End of footer logo column */}
        <div class="footer_list">
          {Data.cryptoJson.menuWidget.map(widget => (
            <div className="col" {...col} key={widget.id}>
              <Heading content={widget.title} {...titleStyle} />
              <List>
                {widget.menuItems.map(item => (
                  <ListItem key={`list__item-${item.id}`}>
                    {item.staticLink ? (
                      <>
                        <a className="ListItem" href={item.url} target="_blank">
                          {item.text}
                        </a>
                      </>
                    ) : (
                      <>
                        <AnchorLink href={item.url} offset={item.offset}>
                          {item.text}
                        </AnchorLink>
                      </>
                    )}
                  </ListItem>
                ))}
              </List>
            </div>
          ))}
        </div>
        {/* End of footer List column */}

        <Box className="row copyRight" {...row}>
          <Text
            content="Copyrights © C-Live, Inc. All rights reserved."
            className="copyRightText"
          />
        </Box>
        <div className="copyRightText_B">
          <a
            href={`${ZENDESK_BASE_URL}/hc/ja/articles/360038186152-サイトポリシー`}
            target="_blank"
          >
            gunsy
            の著作権・商標権などすべての知的財産はシーライヴ株式会社に帰属します。
          </a>
        </div>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  colThree: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-4px",
    mr: "-4px"
  },
  // Footer col one style
  colOne: {
    width: ["100%", "30%", "33%", "33%"],
    mb: ["30px", 0],
    pl: ["0px", 0],
    pr: ["0px", "0px", 0]
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "70%", "67%", "67%"],
    flexBox: true,
    flexWrap: "wrap"
  },
  // Footer col default style
  col: {
    width: ["100%", 1 / 3, 1 / 3, 1 / 3],
    pl: [0, "15px"],
    pr: [0, "15px"],
    mb: ["30px", "30px"]
  },
  // widget title default style
  titleStyle: {
    color: "#FFFFFF",
    fontSize: ["15px", "16px", "16px", "18px", "18px"],
    fontWeight: "600",
    lineHeight: "1.34",
    mb: ["15px", "18px", "18px", "20px", "30px"],
    fontFamily: "Poppins"
  },
  // Default logo size
  logoStyle: {
    width: "128px",
    mb: "15px"
  },
  // widget text default style
  textStyle: {
    color: "#FFFFFF",
    fontSize: "16px",
    mb: "12px",
    fontWeight: "600",
    fontFamily: "Lato"
  }
};

export default Footer;
