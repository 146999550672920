import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Fade from "react-reveal/Fade";
import Box from "reusecore/src/elements/Box";
import Image from "reusecore/src/elements/Image";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import FeatureBlock from "common/src/components/FeatureBlock";
import Container from "common/src/components/UI/Container";
import { BetaSectionWrapper, FeatureSection } from "./betaSection.style";
import Button from "reusecore/src/elements/Button";
import { Link } from "gatsby";
import { GUNSYAPP_BASE_URL, ZENDESK_BASE_URL } from "../../../constant/URL";
import tags from "./tags";
import categories from "./categories";

const BetaSection = ({
  row,
  title,
  description,
  button,
  featureTitleStyle,
  featureDescriptionStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        BETA_FEATURE {
          id
          title
          menuItems {
            id
            text
          }
        }
      }
    }
  `);

  return (
    <BetaSectionWrapper id="betasection">
      <div class="news_link">
        <p class="news_link_1">ニュース・お知らせ </p>
      </div>
      <a
        href={`${ZENDESK_BASE_URL}/hc/ja/categories/360002618091`}
        class="news_navi"
        target="__blank"
      >
        gunsy（グンシー） に関する最新のニュース・お知らせはこちらをご覧ください
      </a>
      <Container>
        <div className="genre-box" id="genre">
          <div className="genre-intro">
            gunsyではこんなジャンル（分野）が売買できます。
          </div>
          <div className="search-button2">
            <a href={`${GUNSYAPP_BASE_URL}/search`} target="_blank">
              <Button title="軍師・商品検索" {...button} />
            </a>
          </div>
          <div className="genres">
            {categories.map((category, key) => (
              <div key={key} className="genre">
                <div className="category">{category}</div>
                {tags[category].map((tag, index) => (
                  <div className="tags">
                    <div className="tag" key={index}>
                      #{tag}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Container>
    </BetaSectionWrapper>
  );
};

// Transactions style props
BetaSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  row: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object,
};

// Trusted default style
BetaSection.defaultProps = {
  // Trusted section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
  },

  title: {
    content: "ニュース・お知らせ",
    fontSize: ["24px", "26px", "30px", "36px", "40px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    fontWeight: "700",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "20px",
    textAlign: ["center", "center"],
  },

  description: {
    content:
      "gunsy（グンシー） に関する最新のニュース・お知らせはこちらをご覧ください。",
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: ["25px", "25px", "30px", "30px", "45px"],
    textAlign: ["center", "center"],
  },
  featureTitleStyle: {
    fontSize: ["18px", "18px", "20px", "20px", "20px"],
    lineHeight: ["1", "1", "1", "1", "1"],
    fontWeight: "500",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "10px",
    textAlign: ["left", "left"],
  },
  // Trusted section description default style
  featureDescriptionStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "27px",
    textAlign: ["left", "left"],
  },
  button: {
    type: "button",
    fontSize: "13px",
    fontWeight: "700",
    borderRadius: "4px",
    pl: "10px",
    pr: "10px",
    ml: "10px",
    colors: "secondaryWithBg",
    minHeight: "auto",
    height: "40px",
  },
};

export default BetaSection;
