import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Poppins', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins', sans-serif;
  }
  p{
    font-family: 'Poppins', sans-serif;
  }

  section {
    position: relative;
  }

  .drawer-content-wrapper{
    @media (max-width: 767px) {
      width: 300px!important;
    }
    .drawer-content {
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li{
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
            margin-right:-10px;
          }
          a{
            font-size: 20px;
            font-weight: 400;
            color: #000;
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              color: rgb(255,148,147);;
            }
            &:before{
              content: '';
              width: 7px;
              height: 7px;
              background: rgb(255,148,147);
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }
          &.is-current {
            a {
              color: rgb(255,148,147);;
              &:before{
                opacity: 1;
                
              }
            }            
          }
        }
      }
      .navbar_drawer_button button{
        width: 100%;
      }
    }

    .reusecore-drawer__close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
      &:before{
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: #6d4efe;
        transform: rotate(45deg);
        display: block;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  .sticky-nav-active {
    .saas_navbar {
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 15px 0;
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {          
          a {
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            font-weight: bold;
            color: #000;
            &:hover {
              color: #0168b3;
            }
          }
          &.is-current {
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            a {
              font-weight: bold;
              color: #0168b3;
            }
          }
        }
      }      
    }
    .hamburgMenu__bar {
      > span {
        background: #2aa275;
      }
    }
  }
  .news_link{
    font-size: 36px;
    text-align: center;       
    width: 100%;
  }
  .news_link_1{
      font-size: 36px;      
      color: #32325D;
      font-weight: 700;
      width: 100%;
  }
  .news_link_1:visited{
    font-size: 36px;
    text-align: center;
    color: #32325D;
    font-weight: 700;
    width: 100%;
  }
  .news_link:visited{
    font-size: 36px;
    text-align: center;
    color: #32325D;
    font-weight: 700;
    width: 100%;
  }
  .news_navi{
    font-size: 16px;
    text-align: center;
    color: #32325D;
    font-weight: 700px;
    margin:0 auto;
    display:block;
    width:100%;
  }

  }
  .saas_navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 30px 0;
    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 40px;    
      line-height: 20px;  
      li {
        display: inline-block;
        max-width: 150px;
        vertical-align: middle;
        text-align: center;
        padding-left: 13px;
        padding-right: 13px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        a {          
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          padding: 5px;
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          transition: 0.15s ease-in-out;
          &:hover {
            color: #ff282f;
          }          
        }
      }
      @media (max-width: 1220px) {
        display: none;
      }
    }    
    .user_guide_white{
      font-size: 16px;
      color:white;
      padding: 5px;
      margin-right:60px;
      margin-left:-30px
      display: none;
    }
    .user_guide_black{
      font-size: 16px;
      color:black;
      padding: 5px;
      margin-right:60px;
      margin-left:-30px
      display: block;
    }
    .support_link{
      font-size: 16px;
      color:black;
      padding: 10px;
      margin-right:20px;
      margin-left:-30px
    }
    .support_link:visited{
      font-size: 16px;
      color:black;
      padding: 10px;
      margin-right:20px;
      margin-left:-30px
    }
    .navbar_button {
      @media (max-width: 1220px) {
        display: none;
      }
      button {
        border-radius: 6px;
        background-color: #ff5b60;
        &:hover {
          box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
          background-color: #FF282F;
        }
        > span {
          font-weight: 700;
          letter-spacing: 1px;
        }
      }
    }
    .navbar_button_1 {
      @media (max-width: 1220px) {
        display: none;
      }
      button {
        border-radius: 6px;
        margin-right: 25px;
        margin-left: 25px;
        background-color: #0168b3;
        &:hover {
          box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
          background-color: #035d9e;
        }
        > span {
          font-weight: 700;
          letter-spacing: 1px;
        }
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 1220px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }
  .button {
    display: flex;
    flex-direction: column;
  }
  .button_0 {
      display: flex;
      flex-wrap: wrap;
  }
  
  .icon_0 {
    display: flex;
    flex-direction: row;
  }

  .login-button {
    button {
      border-radius: 6px;
      margin-bottom: 30px;
      margin-left: 25px;
      background-color: #0168b3;
      @media (max-width: 768px){
        background-color: #88b83e;
      }
      &:hover {
        box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
        background-color: #00467a;
          @media (max-width: 768px){
            background-color: #78a336;
          }
      }
      > span {
        font-weight: 700;
        letter-spacing: 1px;
      }
    }
  }

  .start-button {
    margin-right: 25px;
    button {
      border-radius: 6px;
      margin-left: 50px;
      margin-bottom: 30px;
      background-color: #ff5b60;      
      &:hover {
        box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
        background-color: #FF282F;
      }
      > span {
        font-weight: 700;
        letter-spacing: 1px;
      }
    }
  }
    
  .search-button {
    button {
      padding
      border-radius: 6px;      
      margin-left: 25px;
      background-color: #4472c4;
      width: 250px;
      justify-content: center;
      &:hover {
        box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
        background-color: #365b9c;
      }
      > span {
        font-weight: 700;
        text-align: center;
        letter-spacing: 1px;
      }
    }
  }

  .search-button2 {
    text-align:center;
    button {
      width: 250px;
      border-radius: 6px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;      
      background-color: #4472c4;
      justify-content: center;
      &:hover {
        box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
        background-color: #365b9c;
      }
      > span {
        font-weight: 700;
        letter-spacing: 1px;
      }
    }
  }
  

  .icon_1{
    display: inline;
    @media (min-width: 990px){
      
    }
    @media (min-width: 576px) and (max-width: 990px){
      
    }
    @media (max-width: 576px){
      
    }
  }
  
  .icon_2{
    display: inline;
  }
  .main_title{
    text-align:  center; 
    padding-bottom: 55px;
    position:relative;
    margin-top: -10;
    @media (max-width: 650px){
      top: 10px;
    }
  }
  .lead_sentence{
    line-height: 30px;
    @media (max-width: 650px){      
      position: relative;
      bottom: 10px;
      line-height: 28px;
    }
  }
  .qqq{
    position: relative;
    top: -50px;
    width: 80%;
  }
`;
