import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { cryptoTheme } from "common/src/theme/crypto";
import { ResetCSS } from "common/src/assets/css/style";
import { GlobalStyle, ContentWrapper } from "../containers/Crypto/crypto.style";
import { DrawerProvider } from "common/src/contexts/DrawerContext";
import Navbar from "../containers/Crypto/Navbar";
import Banner from "../containers/Crypto/BannerSection";
import BannerSlider from "../containers/Crypto/BannerSlider";
import Transactions from "../containers/Crypto/Transaction";
import ControlSections from "../containers/Crypto/ControlSection";
import TrustedProofSections from "../containers/Crypto/TrustedProof";
import ScalableSections from "../containers/Crypto/ScalableSection";
import SlideSections from "../containers/Crypto/CryptoSlides";
import BetaSections from "../containers/Crypto/BetaSection";
import Footer from "../containers/Crypto/Footer";
import SEO from "../components/seo";
import GA from "../components/ga";
import Text from "reusecore/src/elements/Text";

export default () => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <Fragment>
        <GA
          googleAnalyticsIds={[
            process.env.GA_TRACKING_ID,
            process.env.GA_TOTAL_TRACKING_ID
          ]}
        />
        <SEO title="gunsy：グンシー　あなたの軍師（コンサルタント）がすぐに見つかる" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <BannerSlider />
          <Transactions />
          <ControlSections />
          <TrustedProofSections />
          <BetaSections />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
